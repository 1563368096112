import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map, tap} from 'rxjs';
import {Prompt, SportsPromptCategory} from './prompt';

@Injectable({
  providedIn: 'root',
})
export class PromptService {
  tags: string[] = [];

  constructor(private readonly httpClient: HttpClient) {
  }

  submitPrompt(prompt: string, audioBlob?: Blob) {
    let formData = new FormData();
    if (audioBlob) {
      formData.append('file', audioBlob, 'recording.wav');
    }
    let options: any = {
      body: formData,
      observe: 'response',
      responseType: 'json',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };
    let promptParam = prompt ? '?prompt=' + encodeURIComponent(prompt) : '';
    return this.httpClient.request('post', environment.API_URL + '/api/admin/prompt' + promptParam, options)
      .pipe(map((response: any) => response.body));
  }

  fetchTags() {
    return this.httpClient
      .get<string[]>(environment.API_URL + '/api/admin/prompt/tag')
      .pipe(
        tap((tags) => {
          this.tags = tags;
        })
      );
  }

  update(prompt: Prompt) {
    return this.httpClient.patch<Prompt>(
      environment.API_URL + '/api/admin/prompt',
      prompt
    );
  }

  replay(prompt: Prompt) {
    return this.httpClient.post<Prompt>(
      environment.API_URL + '/api/admin/prompt/replay',
      prompt
    );
  }

  getAllCategories() {
    return this.httpClient.get<SportsPromptCategory[]>(
      environment.API_URL + '/api/admin/prompt/category'
    );
  }

  getPrompts() {
    return this.httpClient.get<Prompt[]>(
      environment.API_URL + '/api/admin/prompt'
    );
  }

  export(from: Date, to: Date) {
    return this.httpClient.get(environment.API_URL + `/api/admin/prompt/export?from=${from.toISOString()}&to=${to.toISOString()}`,
      {responseType: 'blob'}).pipe(tap((blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'report.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }));
  }
}
